import { BodyMessage } from '../common-types/http/bodyMessage'

function getHeaders(token?: string): HeadersInit {
  return token
    ? {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    : {
        'Content-Type': 'application/json',
      }
}

export function fetcher<Data>(token?: string) {
  return async function (url: string) {
    const result = await fetch(url, {
      method: 'GET',
      headers: getHeaders(token),
    })
    const json = (await result.json()) as BodyMessage<Data>
    if (result.ok && json.data) {
      return json.data as Data
    }
    throw Error(json.message || `Status: ${result.status}`)
  }
}
