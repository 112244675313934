import './App.css'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme'
import { Box } from '@mui/material'
import { useWindowSize } from 'usehooks-ts'
import { TopBar } from './components/TopBar'
import { LandingPage } from './components/LandingPage'

// Needed to avoid white space when switching between portrait and landscape
// See e.g. https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
function setDocHeight(height: number) {
  const doc = document.documentElement
  doc.style.setProperty('--doc-height', `${height}px`)
}

function App() {
  const { height } = useWindowSize()
  setDocHeight(height)

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100vw',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          overflow: 'hidden',
          background: theme.palette.background.default,
        }}
      >
        <TopBar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
          }}
        >
          <LandingPage />
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default App
