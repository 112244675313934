export type BackendArguments = {
  [key: string]: string | number | boolean
}

export function getFullUrl(url: string, args?: BackendArguments): string {
  const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL || '' : ''
  let fullUrl = encodeURI(`${baseUrl}${url}`)

  if (args) {
    Object.keys(args).forEach((key, index) => {
      fullUrl += index === 0 ? '?' : '&'
      fullUrl += `${encodeURIComponent(key)}=${encodeURIComponent(args[key])}`
    })
  }
  return fullUrl
}
