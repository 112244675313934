import { Box, Typography } from '@mui/material'
import { DeviceInfo } from 'common-code-react/common-types/device/deviceInfo'

type Props = {
  device: DeviceInfo | undefined
}

export function DeviceDetails({ device }: Props) {
  return (
    <Box display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='column' marginTop='20px'>
        {device && (
          <>
            <Typography variant='mediumDarkHeader' component='div'>{`Product type: ${device.product}`}</Typography>
            <Typography variant='mediumDarkHeader' component='div'>{`Device id: ${device.deviceId}`}</Typography>
          </>
        )}
        {device?.deviceReport?.geoLocationInfo && (
          <>
            <Typography variant='mediumDarkHeader' component='div'>
              {`Long: ${device.deviceReport.geoLocationInfo.lng}`}
            </Typography>
            <Typography variant='mediumDarkHeader' component='div'>
              {`Lat: ${device.deviceReport.geoLocationInfo.lat}`}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  )
}
