import React from 'react'
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser'
import Login from './Login'
import { b2cPolicies } from './authConfig'

const clientId = process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID || ''
const redirectUri = process.env.REACT_APP_REDIRECT_URI || ''

const configuration: Configuration = {
  auth: {
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    clientId,
    redirectUri,
    knownAuthorities: [b2cPolicies.authorityDomain],
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

const pca = new PublicClientApplication(configuration)

function Authentication(): JSX.Element {
  return (
    <MsalProvider instance={pca}>
      <Login />
    </MsalProvider>
  )
}

export default Authentication
