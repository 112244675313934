import React from 'react'
import FleetDeviceSearch from './FleetDeviceSearch'
import { Customer } from 'common-code-react/common-types/customer/customer'
import { Device } from 'common-code-react/common-types/device/deviceInfo'
import { Box, Typography } from '@mui/material'
import DeviceGroupTree from './DeviceGroupTree'

type TreeContextValues = {
  selectedDevice?: Device
  selectDevice: (device: Device | undefined) => void
  wideMode: boolean
}

export const TreeContext = React.createContext<TreeContextValues>({
  selectedDevice: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectDevice: () => {},
  wideMode: false,
})

type Props = {
  customer: Customer
  selectedDevice?: Device
  selectDevice: (device: Device | undefined) => void
  wideMode: boolean
}

export function SearchableTree({ customer, selectedDevice, selectDevice, wideMode }: Props): JSX.Element {
  return (
    <>
      <Typography variant='largeDarkHeader'>{customer.displayName}</Typography>
      <FleetDeviceSearch customer={customer} selectedDevice={selectedDevice} selectDevice={selectDevice} />
      <TreeContext.Provider
        value={{
          selectedDevice,
          selectDevice,
          wideMode,
        }}
      >
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          {customer.deviceGroups &&
            customer.deviceGroups.map((deviceGroup) => {
              return <DeviceGroupTree key={deviceGroup.name} deviceGroup={deviceGroup} depth={0} />
            })}
        </Box>
      </TreeContext.Provider>
    </>
  )
}
