import React from 'react'
import './index.css'
import ReactDOM from 'react-dom/client'
import Authentication from './auth/Authentication'
// import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Authentication />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app
// reportWebVitals()
