export const b2cPolicies = {
  names: {
    signUpSignIn: process.env.REACT_APP_B2C_SIGN_UP_IN_POLICY || '',
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${process.env.REACT_APP_B2C_NAME || ''}.b2clogin.com/${
        process.env.REACT_APP_B2C_NAME || ''
      }.onmicrosoft.com/${process.env.REACT_APP_B2C_SIGN_UP_IN_POLICY || ''}`,
    },
  },
  authorityDomain: `${process.env.REACT_APP_B2C_NAME || ''}.b2clogin.com`,
}

export const apiConfig = {
  b2cScopes: [`https://${process.env.REACT_APP_B2C_NAME || ''}.onmicrosoft.com/iot-portal-api/user`],
}
