import { useEffect, useState } from 'react'
import mapboxgl from 'mapbox-gl'
import { Marker, useMap } from 'react-map-gl'
import useSupercluster from 'use-supercluster'
import { Chip } from '@mui/material'
import { GeoLocationInfo } from 'common-code-react/common-types/modules/geoLocationInfo'

type GeoJsonPoint = {
  type: 'Feature'
  id?: string
  properties: {
    cluster: boolean
    point_count?: number
    setPopupInfo?: () => void
  }
  geometry: {
    type: 'Point'
    coordinates: number[]
  }
}

export type LocationMarker = {
  location: GeoLocationInfo
  setPopupInfo: () => void
}

type Props = {
  locationMarkers: LocationMarker[]
}

export function MapHandler({ locationMarkers: locationMarkers }: Props): JSX.Element {
  const map = useMap()
  const zoom = map.current ? map.current.getZoom() : 1

  const [points, setPoints] = useState<GeoJsonPoint[]>([])
  const [bounds, setBounds] = useState<[number, number, number, number] | undefined>(
    map.current ? (map.current.getMap().getBounds().toArray().flat() as [number, number, number, number]) : undefined,
  )

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 30, maxZoom: 20 },
  })

  useEffect(() => {
    if (map.current) {
      const locations = locationMarkers.map((locationMarker) => {
        return { lat: locationMarker.location.lat, lng: locationMarker.location.lng }
      })
      if (locations.length > 1) {
        const bounds = mapboxgl.LngLatBounds.convert([
          Math.min(...locations.map((l) => l.lng)),
          Math.min(...locations.map((l) => l.lat)),
          Math.max(...locations.map((l) => l.lng)),
          Math.max(...locations.map((l) => l.lat)),
        ])
        map.current.fitBounds(bounds, { padding: 100 })
      } else if (locations.length === 1) {
        map.current.flyTo({
          center: locations[0],
          zoom: 5,
        })
      }
      setPoints(
        locationMarkers.map((locationMarker) => ({
          type: 'Feature',
          properties: {
            cluster: false,
            setPopupInfo: locationMarker.setPopupInfo,
          },
          geometry: {
            type: 'Point',
            coordinates: [locationMarker.location.lng, locationMarker.location.lat],
          },
        })),
      )

      map.current.on('zoomend', (ev) => {
        if (ev.type === 'zoomend') {
          setBounds(
            map.current
              ? (map.current.getMap().getBounds().toArray().flat() as [number, number, number, number])
              : undefined,
          )
        }
      })
    }
  }, [locationMarkers, map])

  const clusterMarkers = clusters
    .filter((cluster: GeoJsonPoint) => cluster.properties.cluster === true)
    .map((cluster: GeoJsonPoint, index) => {
      return (
        <Marker
          key={index}
          longitude={cluster.geometry.coordinates[0]}
          latitude={cluster.geometry.coordinates[1]}
          color='#0067ff'
          onClick={(e): void => {
            e.originalEvent.stopPropagation()
            const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20)
            if (map.current) {
              map.current.flyTo({
                center: [cluster.geometry.coordinates[0], cluster.geometry.coordinates[1]],
                zoom: expansionZoom,
              })
            }
          }}
        >
          <Chip label={cluster.properties.point_count} color='primary' />
        </Marker>
      )
    })

  const officeMarkers = clusters
    .filter((cluster: GeoJsonPoint) => cluster.properties.cluster !== true)
    .map((cluster: GeoJsonPoint, index) => {
      return (
        <Marker
          key={index}
          longitude={cluster.geometry.coordinates[0]}
          latitude={cluster.geometry.coordinates[1]}
          anchor='bottom'
          color='#00673e'
          onClick={(e): void => {
            e.originalEvent.stopPropagation()
            if (cluster.properties.setPopupInfo) {
              cluster.properties.setPopupInfo()
            }
          }}
        />
      )
    })

  return (
    <>
      {clusterMarkers && clusterMarkers.length > 0 && clusterMarkers}
      {officeMarkers && officeMarkers.length > 0 && officeMarkers}
    </>
  )
}
