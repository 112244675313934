import { Box } from '@mui/material'
import { useMoreInfo } from '../hooks/useMoreInfo'
import { useContext, useState } from 'react'
import { LoginContext } from '../auth/Login'
import { Device, DeviceInfo } from 'common-code-react/common-types/device/deviceInfo'
import { DeviceDetails } from './deviceDetails'
import MapView from './map/MapView'
import { useCustomer } from '../hooks/useCustomer'
import { DeviceReport } from 'common-code-react/common-types/device/deviceReport'
import { GeoLocationInfo } from 'common-code-react/common-types/modules/geoLocationInfo'
import { SearchableTree } from './searchableTree/SearchableTree'

export function setRandomGeoLocation(deviceInfo: DeviceInfo): void {
  if (!deviceInfo.deviceReport) {
    deviceInfo.deviceReport = {} as DeviceReport
  }
  if (!deviceInfo.deviceReport?.geoLocationInfo) {
    deviceInfo.deviceReport.geoLocationInfo = {
      lat: Math.random() * 120 - 50,
      lng: Math.random() * 360 - 180,
    } as GeoLocationInfo
  }
}

export function LandingPage() {
  const loginContext = useContext(LoginContext)

  const { data: customerInfo, error: customerInfoError } = useCustomer(loginContext.accessToken)
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(undefined)

  if (loginContext.account) {
    console.log(JSON.stringify(loginContext.account))
  }
  if (customerInfo) {
    console.log(JSON.stringify(customerInfo))
  }

  const moreInfoUrl = loginContext.account?.idTokenClaims?.moreinfoUrl as string | undefined
  const realMoreInfoUrl = moreInfoUrl && moreInfoUrl.startsWith('http') ? moreInfoUrl : undefined
  const { data: moreInfoData } = useMoreInfo(realMoreInfoUrl)
  if (moreInfoData) {
    console.log(JSON.stringify(moreInfoData))
  }

  const devices = customerInfo?.deviceGroups?.flatMap((g) => g.devices)
  const deviceInfos = devices?.map((d) => d as DeviceInfo)
  if (deviceInfos) {
    deviceInfos.forEach((device) => {
      setRandomGeoLocation(device) // TODO: fetch real data when geolocations are accessable
    })
  }

  return (
    <Box
      component='div'
      sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', overflow: 'hidden', flexGrow: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          borderRight: '3px solid black',
          flexShrink: 0,
          justifyContent: 'flex-start',
          width: '500px',
        }}
      >
        {customerInfo && (
          <>
            <SearchableTree
              customer={customerInfo}
              selectedDevice={selectedDevice}
              selectDevice={setSelectedDevice}
              wideMode={false}
            />
          </>
        )}
      </Box>
      <Box component='div' sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
        <Box
          component='div'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '50%',
            minHeight: '50%',
            maxHeight: '50%',
            borderBottom: '3px solid black',
          }}
        >
          <DeviceDetails device={selectedDevice} />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'column', height: '50%', minHeight: '50%', maxHeight: '50%' }}
        >
          <MapView deviceInfos={deviceInfos} selectedDevice={selectedDevice} />
        </Box>
      </Box>
    </Box>
  )
}
