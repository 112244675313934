import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import { Table, TableHead, TableBody, TableRow, TableCell, SxProps } from '@mui/material'
import { TreeContext } from '../SearchableTree'
import DeviceTableRow from './DeviceTableRow'
import { Device } from 'common-code-react/common-types/device/deviceInfo'

type Props = {
  devices: Device[]
}

const narrowWidths = ['50%', '50%']
const wideWidths = ['50%', '50%']

export default function DeviceList({ devices }: Props): JSX.Element {
  const treeContext = useContext(TreeContext)

  const sx: SxProps = {
    border: 'none',
    overflow: 'hidden',
    textOverflow: 'clip',
    fontStyle: 'italic',
    userSelect: 'none',
    cursor: 'auto',
  }

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Table
        size='small'
        sx={{
          width: '100%',
          whiteSpace: 'nowrap' as const,
          position: 'relative' as const,
          tableLayout: 'fixed' as const,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell width={treeContext.wideMode ? wideWidths[0] : narrowWidths[0]} sx={sx}>
              Device Id
            </TableCell>
            <TableCell width={treeContext.wideMode ? wideWidths[2] : narrowWidths[1]} sx={sx}>
              Product Type
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.map((device) => {
            return <DeviceTableRow key={device.deviceId} device={device} />
          })}
        </TableBody>
      </Table>
    </Box>
  )
}
