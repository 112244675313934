export function fetcherExternal<Data>() {
  return async function (url: string) {
    const result = await fetch(url, {
      method: 'GET',
      headers: {},
    })
    const json = await result.json()
    if (result.ok && json) {
      return json as Data
    }
    throw Error(json.message || `Status: ${result.status}`)
  }
}
