import React, { useContext, useRef } from 'react'
import { Box, TableRow, TableCell, useTheme, SxProps } from '@mui/material'
import { TreeContext } from '../SearchableTree'
import { Device } from 'common-code-react/common-types/device/deviceInfo'
import { useOnScreen } from '../../../hooks/useOnScreen'

type Props = {
  device: Device
}

export default function DeviceTableRow({ device }: Props): JSX.Element | null {
  const theme = useTheme()
  const treeContext = useContext(TreeContext)

  const isSelected = treeContext.selectedDevice === device

  const componentRef = useRef<HTMLElement>(null)
  const isOnScreen = useOnScreen(componentRef)

  React.useEffect(() => {
    if (componentRef && componentRef.current && isSelected && !isOnScreen) {
      componentRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [treeContext.selectedDevice])

  const sx: SxProps = {
    border: 'none',
    overflow: 'hidden',
    textOverflow: 'clip',
    userSelect: 'none',
    ...(isSelected && {
      backgroundColor: theme.palette.success.main,
      color: 'white',
    }),
    cursor: 'pointer',
  }

  const narrowWidths = ['50%', '50%']
  const wideWidths = ['50%', '50%']

  return (
    <>
      <TableRow
        onClick={(event: React.MouseEvent): void => {
          if (treeContext.selectedDevice !== device) {
            treeContext.selectDevice(device)
          } else {
            treeContext.selectDevice(undefined)
          }
        }}
      >
        <TableCell width={treeContext.wideMode ? wideWidths[0] : narrowWidths[0]} sx={sx}>
          {device.deviceId}
        </TableCell>
        <TableCell width={wideWidths[1]} sx={sx}>
          {device.product === 'cao' && 'Chief Airgonomics Officer (Air Image)'}
          {device.product === 'cityconnect' && 'City Connect'}
        </TableCell>
      </TableRow>
      <Box ref={componentRef} />
    </>
  )
}
