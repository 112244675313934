import * as React from 'react'
import Typography from '@mui/material/Typography'
import CamfilLogoWhite from '../assets/camfil-logo-white.svg'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { useMsal } from '@azure/msal-react'
import { useContext } from 'react'
import { LoginContext } from '../auth/Login'
import { useMoreInfo } from '../hooks/useMoreInfo'

export function TopBar() {
  const { instance } = useMsal()

  const loginContext = useContext(LoginContext)

  if (loginContext.account) {
    console.log(JSON.stringify(loginContext.account))
  }

  const moreInfoUrl = loginContext.account?.idTokenClaims?.moreinfoUrl as string | undefined
  const realMoreInfoUrl = moreInfoUrl && moreInfoUrl.startsWith('http') ? moreInfoUrl : undefined
  const { data: moreInfoData } = useMoreInfo(realMoreInfoUrl)

  if (moreInfoData) {
    console.log(JSON.stringify(moreInfoData))
  }

  return (
    <AppBar position='static'>
      <Toolbar variant='dense'>
        <img src={CamfilLogoWhite} alt='Camfil Logo' height='36px' />
        <Typography variant='largeBrightHeader' color={'white'} component='div' sx={{ flexGrow: 1 }} marginLeft='48px'>
          IoT Portal
        </Typography>

        {moreInfoData && (
          <Typography variant='smallBrightHeader' color={'white'} component='div'>
            {moreInfoData.Email}
          </Typography>
        )}
        <IconButton
          color='inherit'
          aria-label='logout'
          onClick={() => {
            instance.logoutRedirect({
              account: instance.getActiveAccount(),
            })
          }}
        >
          <LogoutIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
