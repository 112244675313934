import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariants {
    largeBrightHeader: React.CSSProperties
    largeDarkHeader: React.CSSProperties
    mediumBrightHeader: React.CSSProperties
    mediumDarkHeader: React.CSSProperties
    smallBrightHeader: React.CSSProperties
    smallDarkHeader: React.CSSProperties
    brightText: React.CSSProperties
    darkText: React.CSSProperties
  }

  // allow configuration using `createTheme`
  // eslint-disable-next-line no-unused-vars
  interface TypographyVariantsOptions {
    largeBrightHeader?: React.CSSProperties
    largeDarkHeader?: React.CSSProperties
    mediumBrightHeader?: React.CSSProperties
    mediumDarkHeader?: React.CSSProperties
    smallBrightHeader?: React.CSSProperties
    smallDarkHeader?: React.CSSProperties
    brightText?: React.CSSProperties
    darkText?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  // eslint-disable-next-line no-unused-vars
  interface TypographyPropsVariantOverrides {
    largeBrightHeader: true
    largeDarkHeader: true
    mediumBrightHeader: true
    mediumDarkHeader: true
    smallBrightHeader: true
    smallDarkHeader: true
    brightText: true
    darkText: true
    h1: false
    h2: false
    h3: false
    h4: false
    h5: false
    h6: false
    body1: false
    body2: false
    button: false
    caption: false
    overline: false
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Source Sans Pro',
    fontSize: 14,
    fontWeightRegular: 'normal',
    largeBrightHeader: {
      fontSize: 30,
      color: '#6F767B',
    },
    largeDarkHeader: {
      fontSize: 30,
      color: '#00673E',
    },
    mediumBrightHeader: {
      fontSize: 26,
      color: '#6F767B',
    },
    mediumDarkHeader: {
      fontSize: 26,
      color: '#00673E',
    },
    smallBrightHeader: {
      fontSize: 18,
      color: '#6F767B',
    },
    smallDarkHeader: {
      fontSize: 18,
      color: '#00673E',
    },
    brightText: {
      color: '#6F767B',
    },
    darkText: {
      color: '#00673E',
    },
  },
  palette: {
    primary: {
      main: '#00673E',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FFFFFF',
      main: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#000',
    },
    error: {
      main: '#880000',
    },
    warning: {
      main: '#FF8800',
    },
    success: {
      main: '#00673E',
    },
    info: {
      main: '#000088',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
        outlinedSecondary: {
          color: '#00673E',
          borderColor: '#00673E',
          '&:hover': {
            backgroundColor: '#66AD9315',
            borderColor: '#00673E',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          backgroundColor: '#00000003',
        },
      },
    },
  },
})
