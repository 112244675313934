import React from 'react'
import ExpandableRow from './ExpandableRow'
import DeviceHubIcon from '@mui/icons-material/DeviceHub'
import DeviceList from './deviceList/DeviceList'
import { DeviceGroup } from 'common-code-react/common-types/customer/customer'

type Props = {
  deviceGroup: DeviceGroup
  depth: number
}

export default function DeviceGroupTree({ deviceGroup, depth }: Props): JSX.Element | null {
  return (
    <>
      <ExpandableRow
        label={deviceGroup.name}
        icon={<DeviceHubIcon />}
        depth={depth}
        selected={false}
        select={undefined}
      >
        {deviceGroup.devices && deviceGroup.devices.length > 0 && <DeviceList devices={deviceGroup.devices} />}
      </ExpandableRow>
      {deviceGroup.deviceGroups &&
        deviceGroup.deviceGroups.map((subGroup) => {
          return <DeviceGroupTree key={subGroup.name} deviceGroup={subGroup} depth={depth + 1} />
        })}
    </>
  )
}
