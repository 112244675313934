import 'mapbox-gl/dist/mapbox-gl.css'
import Map, { NavigationControl } from 'react-map-gl'
import { LocationMarker, MapHandler } from './MapHandler'
import { Device, DeviceInfo } from 'common-code-react/common-types/device/deviceInfo'

function GetLocationMarkers(deviceInfos: DeviceInfo[]): LocationMarker[] {
  const locationMarkers: LocationMarker[] = []
  deviceInfos.forEach((deviceInfo) => {
    if (deviceInfo.deviceReport?.geoLocationInfo) {
      locationMarkers.push({
        location: deviceInfo.deviceReport.geoLocationInfo,
      } as LocationMarker)
    }
  })
  return locationMarkers
}

type Props = {
  deviceInfos: DeviceInfo[] | undefined
  selectedDevice?: Device
}

export default function MapView({ deviceInfos, selectedDevice }: Props): JSX.Element {
  const mapBoxUsername = process.env.REACT_APP_MAPBOX_USERNAME || ''
  const mapBoxStyleId = process.env.REACT_APP_MAPBOX_STYLE_ID || ''
  const mapBoxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''
  const mapBoxUrl = `https://api.mapbox.com/styles/v1/${mapBoxUsername}/${mapBoxStyleId}?access_token=${mapBoxAccessToken}`

  const locationMarkers: LocationMarker[] = deviceInfos
    ? selectedDevice
      ? GetLocationMarkers(deviceInfos.filter((di) => di.deviceId === selectedDevice.deviceId))
      : GetLocationMarkers(deviceInfos)
    : []

  return (
    <Map
      style={{ height: '100vh' }}
      mapStyle={mapBoxUrl}
      initialViewState={{
        longitude: 15,
        latitude: 20,
        zoom: 2,
      }}
    >
      {locationMarkers.length > 0 && (
        <>
          <MapHandler locationMarkers={locationMarkers} />
        </>
      )}
      <NavigationControl />
    </Map>
  )
}
