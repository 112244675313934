import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { Box, useTheme } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import { Customer, DeviceGroup } from 'common-code-react/common-types/customer/customer'
import { Device } from 'common-code-react/common-types/device/deviceInfo'

function addDeviceGroup(searchables: Searchable[], deviceGroup: DeviceGroup): void {
  deviceGroup.devices.forEach((device) => {
    searchables.push({
      device,
      searchString: `${device.deviceId}`,
      displayString: `${device.deviceId}`,
    })
  })
  deviceGroup.deviceGroups && deviceGroup.deviceGroups.forEach((dg) => addDeviceGroup(searchables, dg))
}

function craeteSearchableList(customer: Customer): Searchable[] {
  const searchables: Searchable[] = []
  customer.deviceGroups && customer.deviceGroups.forEach((dg) => addDeviceGroup(searchables, dg))
  return searchables
}

export type Searchable = {
  device: Device
  searchString: string
  displayString: string
}

export type SearchItem = {
  id: string
  searchString: string
}

type Props = {
  customer: Customer
  selectedDevice?: Device
  selectDevice: (device: Device) => void
}

function FleetDeviceSearch({ customer, selectedDevice, selectDevice }: Props): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('')
  const [value, setValue] = useState<Searchable | null>(null)
  const theme = useTheme()

  const options = craeteSearchableList(customer)

  useEffect(() => {
    setInputValue('')
  }, [selectedDevice])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '17px 17px', marginBottom: '14px' }}>
      <Autocomplete
        sx={{ bgcolor: 'background.default', borderRadius: 2, width: '100%' }}
        clearOnEscape
        handleHomeEndKeys={false}
        inputValue={inputValue}
        value={value}
        onInputChange={(event, value): void => setInputValue(value)}
        noOptionsText={<Typography variant='darkText'>No options</Typography>}
        onChange={(event: React.SyntheticEvent, value: Searchable | null): void => {
          if (value) {
            setValue(null)
            selectDevice(value.device)
          }
        }}
        renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => {
          return (
            <TextField
              {...params}
              label='Search'
              variant='filled'
              color='info'
              InputLabelProps={{ sx: { color: theme.typography.darkText.color, marginLeft: '8px' } }}
              sx={{ input: { color: theme.typography.darkText.color, marginLeft: '8px' } }}
            />
          )
        }}
        options={options}
        getOptionLabel={(option: Searchable): string => option.searchString}
        renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: Searchable): React.ReactNode => {
          return (
            <li {...props} key={option.device.product + option.device.deviceId}>
              <Typography variant='darkText' sx={{ cursor: 'pointer' }}>
                {option.displayString}
              </Typography>
            </li>
          )
        }}
      />
    </Box>
  )
}

export default FleetDeviceSearch
