import React, { useState } from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
import App from '../App'
import { AccountInfo } from '@azure/msal-browser'
import { Box, Typography } from '@mui/material'
import { apiConfig } from './authConfig'

type LoginContextValues = {
  accessToken: string | null
  account: AccountInfo | null
  logout: (() => void) | null
}

export const LoginContext = React.createContext<LoginContextValues>({
  accessToken: null,
  logout: null,
  account: null,
})

const loginRequest = {
  scopes: ['openid', ...apiConfig.b2cScopes],
}

function Login(): JSX.Element {
  const { instance, inProgress } = useMsal()

  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [account, setAccount] = useState<AccountInfo | null>(null)

  async function getIdToken(): Promise<void> {
    try {
      const allAccounts = instance.getAllAccounts()
      if (Array.isArray(allAccounts) && allAccounts.length > 0) {
        setAccount(allAccounts[0])

        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: allAccounts[0],
        })

        setAccessToken(response.accessToken)
      } else {
        setAccount(null)
        instance.loginRedirect(loginRequest)
      }
    } catch (err) {
      instance.loginRedirect(loginRequest)
    }
  }

  React.useEffect(() => {
    if (inProgress === 'none') {
      getIdToken()
    }
  }, [inProgress])

  function logout() {
    setAccessToken(null)
    setAccount(null)
  }

  return (
    <>
      <AuthenticatedTemplate>
        <LoginContext.Provider value={{ accessToken, account, logout }}>
          <App />
        </LoginContext.Provider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box>
          <Typography variant='brightText'>You have to be logged in</Typography>
        </Box>
      </UnauthenticatedTemplate>
    </>
  )
}

export default Login
