import React, { useContext, useRef, useEffect, ReactNode, MouseEvent } from 'react'
import { Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import { TreeContext } from './SearchableTree'
import { useOnScreen } from '../../hooks/useOnScreen'

type RowLabelProps = {
  label: string
  selected: boolean
  onClick?: (event: MouseEvent) => void
}

function RowLabel({ label, selected, onClick }: RowLabelProps): JSX.Element {
  const theme = useTheme()

  return (
    <Box
      onClick={(event: MouseEvent): void => {
        if (onClick) {
          event.stopPropagation()
          onClick(event)
        }
      }}
    >
      <Typography
        variant='darkText'
        align='left'
        sx={{
          color: selected ? theme.palette.action.selected : theme.palette.text.primary,
          paddingLeft: '10px',
          cursor: onClick ? 'pointer' : 'default',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}

type ExpandableRowProps = {
  label: string
  icon: React.ReactNode
  depth: number
  selected: boolean
  select?: (event: MouseEvent) => void
  children: ReactNode
}

export default function ExpandableRow({
  label,
  icon,
  depth,
  selected,
  select,
  children,
}: ExpandableRowProps): JSX.Element {
  const theme = useTheme()
  const treeContext = useContext(TreeContext)
  const componentRef = useRef<HTMLElement>(null)

  const isOnScreen = useOnScreen(componentRef)
  useEffect(() => {
    if (select && componentRef && componentRef.current && selected && !isOnScreen) {
      componentRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [treeContext.selectedDevice])

  const unselectedSx = {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: `${15 + 20 * depth}px`,
    marginBottom: '3px',
    paddingRight: '20px',
  }

  const selectedSx = {
    ...unselectedSx,
    backgroundColor: theme.palette.info.main,
    color: theme.palette.action.selected,
    fontWeight: 'bold',
  }

  return (
    <Box ref={componentRef} sx={{ width: '100%' }}>
      <Box sx={selected ? selectedSx : unselectedSx}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {icon}
          </Typography>

          <RowLabel label={label} selected={selected} onClick={select} />
        </Box>
      </Box>
      {children}
    </Box>
  )
}
